import React from 'react';
import SampleCodeList from '../../components/SampleCodeList'
import ContentContainer from '../../components/ContentContainer';

export default function Samples ({
  location
}) {
  return (
    <div>
      <ContentContainer>
        <SampleCodeList location={location} />
      </ContentContainer>
      
    </div>
  )
}